<template>
  <div>
    <h3>November 9 2010 (1.1.18)</h3>
    <p>
      <u>Starbase 3001</u>
      <br>- Starbase 3001 is open! Enter from Nimbus Station, and check out the new cool areas MoonBase, DeepFreeze and Portabello.
    </p>
    <p>
      <u>New Airport Models</u>
      <br>- 66 new airport models have been added, and can be purchased from the models vendor in Brick Annex in Nimbus Station. Limited time only!
    </p>
    <p>
      <u>Power-Ups</u>
      <br>- New power-up stacks have been created for 2, 3, 5, and 10 point values.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Buried treasure chests display the correct icon when you don’t have a pet.
      <br>- Bella Pepper has new hair!
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- You will no longer be stunned when a Stromling Ape is killed while you are building an anchor.
    </p>
    <p>
      <u>Minigames</u>
      <br>- All Minigames now require you to pay 1 green Imaginite crystal to play. A mission has been added to Melodie Foxtrot after you complete her existing mission chain, introducing Imaginite crystals.
      <br>- The shooting gallery experience should now be much smoother.
      <br>- When racing, vehicles no longer are stuck with extra tire friction.
      <br>- In the Dragon Den, the treasure chest now contains faction tokens for all team members.
    </p>
    <p>
      <u>Mail</u>
      <br>- You are now informed of your actual number of unread messages when receiving a new mail notification.
      <br>- Attachments can be accessed more rapidly.
      <br>- Cancelling a mail reply will clear attachments from the reply message.
    </p>
    <p>
      <u>Chat</u>
      <br>- The list of auto-suggested words for chat has been updated.
    </p>
    <p>
      <u>Achievements</u>
      <br>- A number of items have had their stats increased or their damage increased, to reflect the difficulty of the achievements they are rewarded for.
    </p>
    <p>
      <u>Quickbuilds</u>
      <br>- Quickbuild brick piles should now appear correctly.
    </p>
    <p>
      <u>Passport</u>
      <br>- The passport tutorial now works correctly and allows you to go back in the tutorial flow if something is incorrectly chosen.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- ‘On Impact’ behavior drop-down now has ‘Boundary’ as an option.
      <br>- Some behaviors have new names.
    </p>
    <p>
      <u>Getting Smashed</u>
      <br>- Health, Armor and Imagination are now reduced to 0 when you get smashed and return to the default values when you rebuild.
    </p>
    <p>
      <u>Property</u>
      <br>- There is no longer an option to auto renew property leases.
      <br>- Property Navigation will now allow you to search for text found in default property Names and Descriptions.
    </p>
  </div>
</template>
